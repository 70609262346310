import { useQuery, useManualQuery } from 'graphql-hooks';

const searchQuestionsQuery = `
query searchQuestions($query: String!, $status: String!, $offset: Int, $limit: Int) {
  searchQuestions (query: $query, status: $status, offset: $offset, limit: $limit) {
    offset
    total
    questions {
      id
      itemId
      itemName
      itemThumbnail
      permalink
      clientId
      clientName
      text
      answer
      status
      totalTransactions
      completedTransactions
      canceledTransactions
      positiveRating
      neutralRating
      negativeRating
      skuItem
      statusItem,
      itemPrice,
      itemCurrency,
      products {
        seller_custom_field
        title
        price
        currency_id
        description
        pictures {
          link
          variant
          width
          height
          id
        }
        attributes {
          name
          value
        }
        catalog_listing
        sold_quantity
        available_quantity
        catalog_product_id
        id
        status
        start_time
        permalink
        categoryPath
      }
    }
  }
}
`;

const useSearchQuestions = (query, status) => {
  const { loading, error, data } = useQuery(searchQuestionsQuery, {
    variables: {
      query,
      status
    }
  });
  return { loading, error, data }; 
}

const useSearchQuestionsManual = () => {
  const [searchQuestions, { loading, error, data }] = useManualQuery(searchQuestionsQuery);
  const { questions, offset, total } = data ? data.searchQuestions : { questions: [], offset: 0, total: 0 };
  return { searchQuestions, loading, error, questions, offset, total }; 
}

export { useSearchQuestions, useSearchQuestionsManual, searchQuestionsQuery };