import React, { useEffect, useState } from 'react';
import {
  useManualProductCopilotSubscription,
  ManualProductFormCopilotStatus,
} from 'hooks/useManualProductCopilotSubscription';
import { AddManualProduct } from './AddManualProduct';
import { ProgressDialog } from './ProgressDialog';
import ReactDOM from 'react-dom';
import { usePublishProductManual } from 'hooks/usePublishProductManual';
import { PRODUCT_FORM_STATES } from '../types/AddCatalogProduct.types';
import { useGetGridOptionsManual } from 'hooks/useGetGridOptions';
import { ProgressDialogNew } from './ProgressDialogNew';

export const AddManualProductCopilot = ({
  asin,
  closeButtonLabel,
  onCancel,
  onPublish,
}: {
  asin: string;
  closeButtonLabel?: string;
  onCancel: () => void;
  onPublish: (permalink: string) => void;
}) => {
  const {
    publishProduct,
    loading: publishingLoading,
    error: publishingError,
    publishProductResponse,
  } = usePublishProductManual();
  const {
    getGridOptions,
    loading: gridOptionsLoading,
    data: gridOptionsData,
  } = useGetGridOptionsManual();

  const [copilotData, setCopilotData] = React.useState<any>(null);
  const [done, setDone] = React.useState(false);
  const [productInserted, setProductInserted] = React.useState(false);
  const [showCreationProductResult, setShowCreationProductResult] =
    useState(false);

  useManualProductCopilotSubscription(asin, (data) => {
    if (data?.status === ManualProductFormCopilotStatus.DONE) {
      setDone(true);
    }
    if (!done) setCopilotData(data);
  });

  useEffect(() => {
    if (publishProductResponse) {
      setShowCreationProductResult(
        publishProductResponse.publishManualProduct.id
      );
    }
  }, [publishProductResponse]);

  const onPublishItem = async (payload: any) => {
    const { data } = await publishProduct({
      variables: payload,
    });

    console.log('>> responsePublish: ', data);
    const newProductId = data?.publishProductManual?.id;
    setShowCreationProductResult(newProductId);
  };

  let currentFormState: PRODUCT_FORM_STATES;
  if (publishingLoading) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_LOADING;
  } else if (publishingError) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_ERROR;
  } else if (showCreationProductResult) {
    currentFormState = PRODUCT_FORM_STATES.SUBMIT_SUCCESS;
  } else {
    currentFormState = PRODUCT_FORM_STATES.SHOW_SUCCESS;
  }

  return ReactDOM.createPortal(
    <>
      {copilotData?.status === ManualProductFormCopilotStatus.IN_PROGRESS ||
        !copilotData ? (
        // <ProgressDialog
        //   title='Llenando automáticamente el formulario'
        //   progress={
        //     copilotData
        //       ? Math.ceil((copilotData.progress / copilotData.totalWork) * 100)
        //       : 0
        //   }
        //   onCancel={onCancel}
        // />
        <ProgressDialogNew
          title=''
          progress={
            copilotData
              ? Math.ceil((copilotData.progress / copilotData.totalWork) * 100)
              : 0
          }
          onCancel={onCancel}
        />
      ) : (
        <AddManualProduct
          visible={!productInserted}
          identifier={asin}
          closeButtonLabel={closeButtonLabel}
          onCancel={onCancel}
          onPublish={(permalink: string) => {
            setProductInserted(true);
            onPublish(permalink);
          }}
          titleAsinProduct={copilotData?.suggestion?.title || ''}
          price={copilotData?.suggestion?.price || 0}
          currency={copilotData?.suggestion?.currency || 'UYU'}
          productDescriptionAsin={copilotData?.suggestion?.description || ''}
          amazonImages={copilotData?.suggestion?.amazonImages || []}
          mlImages={copilotData?.suggestion?.mlImages || []}
          productImages={copilotData?.suggestion?.images || []}
          productName={copilotData?.suggestion?.title || ''}
          categoryPath={copilotData?.suggestion?.categoryPath || []}
          attributeValues={copilotData?.suggestion?.attributes || []}
          onSubmit={(payload) => onPublishItem(payload)}
          currentFormState={currentFormState}
          publishProductResponse={publishProductResponse}
          showCreationProductResult={showCreationProductResult}
        />
      )}
    </>,
    document.getElementById('modal-root')!
  );
};
