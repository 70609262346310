import React, { useState } from 'react';
import AlertMessage from 'components/utils/AlertMessage';
import AnswerLabel from './labels/AnswerLabel';
import { DANGERMSGALERT } from 'components/utils/constants';
import { AIAnswerIcon } from './icons/AIAnswerIcon';
import { CatalogIcon } from './icons/CatalogIcon';
import { SendAnswerIcon } from './icons/SendAnswerIcon';
import WorldIcon from 'components/utils/iconComponents/WorldIcon';
import { useGetMlItemByIdManual } from 'hooks/useGetMlItemById';
import { AddManualProduct } from 'components/addProduct/manual/AddManualProduct';
import { UpdateItemById, useEditItemByIdManual } from 'hooks/useEditItemById';

function QuestionCard({
  item,
  loadingSearchLabels,
  errorSearchLabels,
  labelError,
  labels,
  handlerLabelAnswer,
  userEnteredAnswers,
  handleAnswerChange,
  showAnswerQuestionButton,
  sendAnswerEnabled,
  answerWithAI,
  answerWithCatalog,
  handleAnswerQuestion,
}) {
  const [openModalProductForm, setOpenModalProductForm] = useState(false);

  const { updateItemById, loading: loadingFetch } = useEditItemByIdManual();
  const { getMlItemById, loading: loadingMlItemById } =
    useGetMlItemByIdManual();

  const editItemPayload = async (itemId, payload) => {
    // console.info('payload...', payload);

    let newPayload;
    if (!payload.hasOwnProperty('fromAttributesTab')) {
      newPayload = Object.assign(payload, {
        //asignar las variables correctas para ML
        available_quantity: payload.stock,
        seller_custom_field: payload.sku,
        currency_id: payload.currencyId,
        category_id: payload.categoryId,
      });
      newPayload.pictures = newPayload.picturesIds;
      delete payload.stock;
      delete payload.sku;
      delete payload.currencyId;
      delete payload.categoryId;
      delete newPayload.picturesIds;
    } else {
      delete payload.available_quantity;
      delete payload.category_id;
      delete payload.currency_id;
      delete payload.pictures;
      delete payload.seller_custom_field;
      delete payload.fromAttributesTab;
      newPayload = payload;
    }

    console.info('newPayload...', newPayload);

    // query update ML
    const { data } = await updateItemById({
      variables: {
        itemId,
        formData: newPayload,
      },
    });

    console.info('data...', data);

    if (data) {
      setOpenModalProductForm(false);
    }
  };

  const onSendCorrectionToMlHandler = async (itemId, payload) => {
    console.info('payload to correction...', payload);
    //usamos esta funcion mientras se envia correccion a ML
    const newPayload = {
      available_quantity: payload.stock,
      currency_id: payload.currencyId,
      price: payload.price,
    };

    const { data } = await updateItemById({
      variables: {
        itemId,
        formData: newPayload,
      },
    });

    if (data) {
      console.info('data..', data);
      setOpenModalProductForm(false);
    }
  };

  return (
    <>
      <div className='rounded-xl flex flex-col h-full p-6 overflow-hidden shadow-lg bg-white border border-gray-200'>
        <div className='flex flex-grow'>
          <div className='w-1/4'>
            <a href={item?.permalink} target='_blank' rel='noreferrer'>
              <img
                className='w-24 h-24 shadow-sm border border-gray-200 rounded-md'
                src={item?.itemThumbnail}
                alt='img'
              />
            </a>
          </div>
          <div className='w-3/4 pl-[5px]'>
            <div className='flex justify-between h-[40px]'>
              <span className='text-sm font-bold mr-0.5 text-ellipsis'>
                {item.itemName}
              </span>
              <div
                className={`w-[68px] h-[36px] py-1 px-3 border-2 border-[#D0D5DD] rounded-lg ${
                  loadingMlItemById ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                aria-hidden={true}
                onClick={() => setOpenModalProductForm(true)}
              >
                <span className='text-sm font-semibold text-gray-700'>
                  Editar
                </span>
              </div>
            </div>
            <div className='justify-between flex pt-6'>
              <div className='flex'>
                <span
                  className={`px-4 py-2 rounded-full ${
                    item.statusItem == 'Activo'
                      ? 'bg-green-800 text-white'
                      : item.statusItem == 'Pausado'
                      ? 'bg-orange-200 text-orange-700'
                      : item.statusItem == 'Cerrado' &&
                        'bg-red-200 text-red-700'
                  } text-xs font-bold`}
                >
                  {item.statusItem[0].toUpperCase() + item.statusItem.slice(1)}
                </span>
              </div>
              <div className='flex p-1 items-end'>
                <span className='text-sm'>{item.itemCurrency}</span>
                <span className='text-sm ml-1'>
                  ${new Intl.NumberFormat('es-UY').format(item.itemPrice)}
                </span>
              </div>
              <div className='flex items-end p-1 text-primary-700'>
                <span>
                  <WorldIcon />
                </span>
                <span className='text-sm ml-1'>
                  <a
                    href={`https://amazon.com/dp/${item.skuItem}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {item.skuItem}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='py-4'>
          <div className='w-full text-primary-700 font-bold flex text-sm '>
            <span className='ml-1'>{item.clientName}</span>
          </div>
          <div className='w-full p-1 text-sm'>{item.text}</div>
          {loadingSearchLabels && item.status === 'UNANSWERED' && (
            <span>cargando...</span>
          )}
          {errorSearchLabels && item.status === 'UNANSWERED' && (
            <AlertMessage
              message={labelError}
              title='Error: '
              type={DANGERMSGALERT}
            />
          )}
          {labels.length > 0 && item.status === 'UNANSWERED' && (
            <div key={item.id}>
              <AnswerLabel
                labels={labels}
                handlerLabelAnswer={handlerLabelAnswer}
                elementId={item.id}
              />
            </div>
          )}
          {(item.status === 'UNANSWERED' && (
            <textarea
              name='textareaAnswer'
              id='textareaAnswer'
              cols='30'
              rows='5'
              className={`p-2 border-2 rounded-md border-gray-300 focus:outline-none focus:border-gray-300 w-full resize-none`}
              value={userEnteredAnswers[item.id]}
              onChange={(event) =>
                handleAnswerChange(item.id, event.target.value)
              }
            ></textarea>
          )) || (
            <div className='h-auto w-full p-0.5 rounded-t-lg border-2 border-primary-100 text-primary-200 font-bold flex text-sm '>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5'
                  />
                </svg>
              </span>
              <span className='ml-2'>{item.answer}</span>
            </div>
          )}
        </div>
        {item.status === 'UNANSWERED' && showAnswerQuestionButton && (
          <div className='justify-between flex'>
            <div className='flex items-center space-x-4'>
              <button
                className={`bg-primary-50 text-primary-700 border-2 border-primary-200 font-bold rounded-md px-4 py-2 text-sm ${
                  sendAnswerEnabled ? 'cursor-pointer' : 'cursor-normal'
                }`}
                onClick={() => answerWithAI(item)}
              >
                <div className='flex items-center'>
                  <AIAnswerIcon />
                  <span className='ml-2'>Respuesta IA</span>
                </div>
              </button>
              <button
                className={`bg-primary-50 text-primary-700 border-2 border-primary-200 font-bold rounded-md px-4 py-2 text-sm ${
                  sendAnswerEnabled ? 'cursor-pointer' : 'cursor-normal'
                }`}
                onClick={() => answerWithCatalog(item)}
              >
                <div className='flex items-center'>
                  <CatalogIcon />
                  <span className='ml-2'>Venta cruzada</span>
                </div>
              </button>
            </div>
            <button
              className={`bg-primary-700 text-white font-bold rounded-md px-4 py-2 text-sm ${
                sendAnswerEnabled ? 'cursor-pointer' : 'cursor-normal'
              }`}
              onClick={() =>
                sendAnswerEnabled &&
                item.statusItem == 'active' &&
                handleAnswerQuestion(item.id)
              }
            >
              <div className='flex items-center'>
                <SendAnswerIcon />
                <span className='ml-2'>Responder</span>
              </div>
            </button>
          </div>
        )}
      </div>
      <AddManualProduct
        visible={openModalProductForm}
        identifier={item.products?.seller_custom_field}
        closeButtonLabel={() => null}
        onCancel={() => setOpenModalProductForm(false)}
        onPublish={() => null}
        titleAsinProduct={item.products?.title || ''}
        price={item.products?.price || 0}
        currency={item.products?.currency_id || ''}
        productDescriptionAsin={item.products?.description || ''}
        productImages={item.products?.pictures || []}
        productName={item.products?.title || ''}
        categoryPath={item.products?.categoryPath || []} //--> falta
        attributeValues={item.products?.attributes || []}
        formTitle='Editar producto'
        buttonName={'Editar'}
        onSubmit={(payload) =>
          !item.products?.catalog_listing
            ? editItemPayload(item.products?.id, payload)
            : onSendCorrectionToMlHandler(item.products?.id, payload)
        }
        loadingFetch={loadingFetch}
        soldQuantity={item.products?.sold_quantity}
        itemId={item.products?.id}
        stock={item.products?.available_quantity}
        catalog_listing={item.products?.catalog_listing}
        permalink={item.products?.permalink || ''}
        catalog_product_id={item.products?.catalog_product_id || ''}
      />
    </>
  );
}

export default QuestionCard;
